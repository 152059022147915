import React from "react";

const ContactUsIcon = () => {
  
  return (
  	<div class="floating-btn">
      <button className="btn telegram-btn"
        onClick={() => {
          window.open("https://t.me/CSGofficial", "_blank");
        }}
      >
        <span className="contact-icon"><i className="fa-brands fa-telegram"></i></span>
      </button>
      <br></br>
      <button className="btn telegram-btn mt-2"
        onClick={() => {
          window.open("https://t.me/CSgalfred", "_blank");
        }}
      >
        <span className="contact-icon"><i className="fa-sharp fa-solid fa-comment"></i></span>
      </button>
      <br></br>
      <button className="btn contact-btn mt-2"
        onClick={() => {
          window.open("https://t.me/CSgalfred", "_blank");
        }}
      >
        <span className="contact-icon"><i className="fa-solid fa-headset"></i></span>
      </button>
    </div>
  );

};

export default ContactUsIcon;