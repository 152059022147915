import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { t } from '../translation.js';

import { useLogin } from '../hooks/useLogin';

import FormLogo from '../assets/img/hero-logo.png';

const Login = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
		// console.log("Login");
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset login-bg">
			<div className="container">
				<div className="row">
					<div className="col-12 form-page-padding">
						{/* <h3 className="brand-primary text-center mb-3"><strong>登入户口</strong></h3> */}
						<div className="form-container">
							<div className="w-100 text-center">
								<img className="form-logo mb-5" src={FormLogo}></img>
							</div>
							<form className="w-100" onSubmit={handleSubmit}>
								<div className="mb-2">
									<label htmlFor="username">
										<strong className="">{t('login-id')}：</strong>
									</label>
									<br></br>
									<input
										type="text"
										placeholder={t('login-placeholder1')}
										autoComplete='off'
										name='username'
										className='text-lowercase w-100'
										onChange={(e) => setUsername(e.target.value.toLowerCase())}
									/>
								</div>
								<div className="mt-3 mb-2">
									<label htmlFor="password">
										<strong className="">{t('login-password')}：</strong>
									</label>
									<br></br>
									<input
										type="password"
										placeholder={t('login-placeholder2')}
										name='password'
										className='w-100'
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<button disabled={isLoading} type="submit" className="btn action-btn py-2 mt-3">
									<p className="text-white mb-0"><strong>{t('login-btn')}</strong></p>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
							<p className="mt-3 mb-0" style={{fontSize:"14px"}}>{t('login-register')} <a href="/register">{t('login-register2')}</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login;