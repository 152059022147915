import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useSignup = () => {

    const { dispatch } = useAuthContext();

    const [registering, setRegistering] = useState(false);
    const [registerFeedback, setRegisterFeedback] = useState("");
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const userSignup = async (referrer, username, national_id, password) => {
        setRegisterFeedback("注册中...");
        setRegistering(true);

        const response = await fetch('https://api.csg9.com/user/register', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                referrer,
                username,
                national_id,
                password
            })
        })
        const json = await response.json();
        if (!response.ok) {
            console.log("Register failed:", json.error);
            setRegisterFeedback("注册失败，请稍后再尝试");
            setRegistering(false);
        }
        if (response.ok) {
            console.log("Register success.");
            setRegisterSuccess(true);
            setRegisterFeedback("注册成功，正在登入...");
            setRegistering(false);

            // save the user to local storage
            localStorage.setItem('csg_login', JSON.stringify(json));

            // update the auth context
            dispatch({type: 'LOGIN', payload: json});

            window.location.href = "/profile";
        }

    }

    return { userSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess };
}