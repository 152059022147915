import React, { useEffect, useState } from 'react';

import { t } from '../translation.js';

import HeroLogo from '../assets/img/hero-logo.png';
import AboutUs from '../assets/img/about-us.jpg';
import AboutUs2 from '../assets/img/about-us-2.png';
import Client1 from '../assets/img/c1.jpg';
import Client2 from '../assets/img/c2.jpg';
import Client3 from '../assets/img/c3.jpg';
import Client4 from '../assets/img/c4.jpg';
import Client5 from '../assets/img/c5.jpg';
import Client6 from '../assets/img/c6.jpg';
import Client7 from '../assets/img/c7.jpg';
import Client8 from '../assets/img/c8.jpg';
import RankRed from '../assets/img/rank-red.png';
import RankSilver from '../assets/img/rank-silver.png';
import RankGold from '../assets/img/rank-gold.png';
import RankPlatinum from '../assets/img/rank-platinum.png';
import WhyUs1 from '../assets/img/whyus-1.png';
import WhyUs2 from '../assets/img/whyus-2.png';
import WhyUs3 from '../assets/img/whyus-3.png';
import Roadmap1 from '../assets/img/roadmap-1.jpg';
import Roadmap2 from '../assets/img/roadmap-2.jpg';
import EndHeroBanner from '../assets/img/end-hero-banner.jpg';

const HomePage = () => {

	useEffect(() => {

	}, []);

	return (
		<>
			{/* Hero Banner */}
			<section className="hero-banner py-5">
				<div className="container">
				<div className="row">
					<div className="col-12 p-0 text-center">
						<img className="hero-logo" src={HeroLogo}></img>
					</div>
				</div>
				</div>
			</section>


			{/* About Us 1 */}
			<section className="about-us">
				<div className="container-fluid">
				<div className="row">
					<div className="col-md-6 py-5 px-0 d-flex align-self-center">
					<div className="text-white text-center text-md-right" style={{marginLeft:'80px', marginRight:'50px'}}>
						<h2>{t('about-us')}</h2>
						<div>
						<p>{t('about-us-p1')}</p>
						<p>{t('about-us-p2')}</p>
						</div>
					</div>
					</div>
					<div className="col-md-6 px-0">
					<img className="w-100 h-100" style={{objectFit:'cover'}} src={AboutUs}></img>
					</div>
				</div>
				</div>
			</section>


			{/* Clients List */}
			<section className="client-list">
				<div className="w-100 d-flex justify-content-center flex-wrap">
				<img src={Client1} />
				<img src={Client2} />
				<img src={Client3} />
				<img src={Client4} />
				<img src={Client5} />
				<img src={Client6} />
				<img src={Client7} />
				<img src={Client8} />
				</div>
			</section>


			{/* About Us 2 */}
			<section className="about-us-2">
				<div className="container">
				<div className="row">
					<div className="col-md-6 text-center">
					<img className="about-us-2-img" src={AboutUs2}></img>
					</div>
					<div className="col-md-6 d-flex align-self-center">
					<div className="pt-5 pl-md-4">
						<h2 className="brand-dark">{t('about-us2')}</h2>
						<p className="brand-dark">{t('about-us2-p1')}</p>
						<p className="brand-dark">{t('about-us2-p2')}</p>
					</div>
					</div>
				</div>
				</div>
			</section>


			{/* Roadmap */}
			<section className="roadmap">
				<div className="container-fluid">
				<div className="row">
					<div className="col-md-6 px-0">
					<img className="w-100 h-100" style={{objectFit:'cover'}} src={Roadmap1} />
					</div>
					<div className="col-md-6 d-flex align-self-center">
					<div className="text-white text-center text-md-left">
						<h2>{t('roadmap')}</h2>
						<p>{t('roadmap-p')}</p>
					</div>
					</div>
				</div>
				<div className="row d-none d-md-flex">
					<div className="col-md-6 d-flex align-self-center justify-content-end">
					<div className="text-white text-center text-md-right">
						<h2>{t('roadmap2')}</h2>
						<p>{t('roadmap2-p')}</p>
					</div>
					</div>
					<div className="col-md-6 px-0">
					<img className="w-100 h-100" style={{objectFit:'cover'}} src={Roadmap2} />
					</div>
				</div>

				{/* Show on tablet and mobile */}
				<div className="row d-block d-md-none">
					<div className="col-md-6 px-0">
					<img className="w-100 h-100" style={{objectFit:'cover'}} src={Roadmap2} />
					</div>
					<div className="col-md-6 d-flex align-self-center">
					<div className="text-white text-center text-md-right">
						<h2>{t('roadmap2')}</h2>
						<p>{t('roadmap2-p')}</p>
					</div>
					</div>
				</div>
				</div>
			</section>

			{/* Why Us */}
			<section className="why-us">
				<div className="container">
				<div className="row">
					<div className="col-sm-4 text-center pb-5 pb-sm-0">
					<img className="w-75" src={WhyUs1} />
					<div className="brand-dark pt-4">
						<p>{t('whyus-1')}</p>
						<p>{t('whyus-2')}</p>
					</div>
					</div>
					<div className="col-sm-4 text-center pb-5 pb-sm-0">
					<img className="w-75" src={WhyUs2} />
					<div className="brand-dark pt-4">
						<p>{t('whyus-3')}</p>
						<p>{t('whyus-4')}</p>
					</div>
					</div>
					<div className="col-sm-4 text-center pb-5 pb-sm-0">
					<img className="w-75" src={WhyUs3} />
					<div className="brand-dark pt-4">
						<p>{t('whyus-5')}</p>
						<p>{t('whyus-6')}</p>
					</div>
					</div>
				</div>
				</div>
			</section>



			{/* End Hero Banner */}
			<section className="end-hero-banner d-flex justify-content-center align-items-center">
				<img className="end-hero-banner-img" src={EndHeroBanner}></img>
			</section>
		</>
	);

};

export default HomePage;