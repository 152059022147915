import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink, useParams } from 'react-router-dom';
import { setLanguage, t } from '../translation.js';

import { useAuthContext } from '../hooks/useAuthContext';

import HeaderLogo from "../assets/img/csg-logo-white.png";

const Header = (props) => {

  const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};

  useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);
  
  return (
      <section className="header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-4 text-center text-md-left">
                <NavLink to="/">
                  <button className="btn">
                    <img className="header-logo" src={HeaderLogo}></img>
                  </button>
                </NavLink>
            </div>
            <div className="col-12 col-md-8 p-0 text-center text-md-right">
              <NavLink to="/news">
                <button className="main-btn ml-2">
                  {t('news-main-title')}
                </button>
              </NavLink>

              <div className="language-switch">
                <select id="language-select" value={props.currentLanguage} onChange={props.changeLanguage}>
                  <option value="zh">中文</option>
                  <option value="en">ENG</option>
                </select>
              </div>

              {!userData ? (
                <>
                  <NavLink to="/login">
                    <button className="main-btn ml-2">
                      <i className="fa-solid fa-user mr-2"></i>{t('login-btn')}
                    </button>
                  </NavLink>
                </>
              ):(
                <>
                  <NavLink to="/profile">
                    <button className="main-btn ml-2">
                      <i className="fa-solid fa-user mr-2"></i>{userData.username}
                    </button>
                  </NavLink>
                </>
              )}

            </div>
          </div>
        </div>
      </section>
  );

};

export default Header;