import { useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useChangePassword = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const updatePassword = async (userToken, userID, newPass) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.csg9.com/user/update/password', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            },
			body: JSON.stringify({userID, newPassword: newPass})
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            // remove user from storage
            localStorage.removeItem('csg_login');

            setIsLoading(false);

            window.location.href = "/login";
		}
    }

    return { updatePassword, isLoading, error };
}