// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
// import Moment from 'moment';

import { setLanguage, t } from './translation.js';

import HomePage from "./pages/home.js";
import NewsPage from "./pages/news.js";
import LoginPage from "./pages/login.js";
import RegisterPage from "./pages/register.js";
import ProfilePage from "./pages/profile.js";
import WalletPage from "./pages/wallet.js";
import TeamPage from "./pages/team.js";
import InvestPage from "./pages/invest.js";

import ContactUsIcon from "./components/contact-us-icon.js";
import Header from './components/header.js';

import { useAuthContext } from './hooks/useAuthContext';

function App() {

  const { loggedInUser } = useAuthContext();

  const [currentLanguage, setCurrentLanguage] = useState('zh');

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    setCurrentLanguage(selectedLanguage);
  };


	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);


  return (
    <>

      <ContactUsIcon></ContactUsIcon>

      <Router>
        <Header setLanguage={setLanguage} setCurrentLanguage={setCurrentLanguage} changeLanguage={changeLanguage}></Header>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/news" element={<NewsPage/>}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/register" element={<RegisterPage/>}/>
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/team" element={<TeamPage/>}/>
          <Route path="/wallet" element={<WalletPage/>}/>
          <Route path="/invest" element={<InvestPage/>}/>
        </Routes>
      </Router>

    </>
  );
}

export default App;