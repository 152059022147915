import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import BottomMenu from '../components/bottom-menu';

import { useAuthContext } from '../hooks/useAuthContext';

const TeamPage = () => {

	
	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("复制成功");
	  setTimeout(() => setCopied(false), 3000);
	}

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						{userData ? (
							<div className="col-12 custom-page-padding">
								<div className="data-container">
									<h3 className="">{t('team-title')}</h3>
									<hr></hr>
									{userData && userData.rank == 0 ? (
										<>
											<p className="mb-2"><strong>{t('my-level')}:</strong> {t('no-level')}</p>
										</>
									):(
										<></>
									)}
									{userData && userData.rank == 1 ? (
										<>
											<p className="mb-2"><strong>{t('my-level')}:</strong> {t('red-level')}</p>
											<img className="profile-rank" src={RedRank}></img>
										</>
									):(
										<></>
									)}
									{userData && userData.rank == 2 ? (
										<>
											<p className="mb-2"><strong>{t('my-level')}:</strong> {t('silver-level')}</p>
											<img className="profile-rank" src={SilverRank}></img>
										</>
									):(
										<></>
									)}
									{userData && userData.rank == 3 ? (
										<>
											<p className="mb-2"><strong>{t('my-level')}:</strong> {t('gold-level')}</p>
											<img className="profile-rank" src={GoldRank}></img>
										</>
									):(
										<></>
									)}
									{userData && userData.rank == 4 ? (
										<>
											<p className="mb-2"><strong>{t('my-level')}:</strong> {t('plat-level')}</p>
											<img className="profile-rank" src={PlatinumRank}></img>
										</>
									):(
										<></>
									)}
									
									<h5 className="mt-3">{t('team-item1')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.direct_sales).toFixed(2)}</span><strong>USDT</strong></p>
									</div>
									<h5 className="mt-3">{t('team-item2')}</h5>
									<div className="wallet-balance">
										<p className="mb-0"><span className="balance-amount mr-2">{parseFloat(userData.total_team_sales-userData.direct_sales).toFixed(2)}</span><strong>USDT</strong></p>
									</div>
								</div>
								
								{userData ? (
									<div className="data-container mt-5">
										<h3 className="">{t('referral-link')}</h3>
										<hr></hr>
										<p className="mb-2">
											<MiddleEllipsis><span className="mt-1 mb-0" style={{fontSize:"14px"}}>https://csg9.com/register?referral={userData.username}</span></MiddleEllipsis>
											<CopyToClipboard 
												text={"https://csg9.com/register?referral="+userData.username}
												onCopy={() => copyReferral()}>
												<button className="btn action-btn py-2 mt-3">
													{!copied ? (
														<p className="text-white mb-0"><strong>{t('copy-link')}</strong></p>
													):(
														<p className="text-white mb-0"><strong>{t('copy-success')}</strong></p>
													)}
												</button>
											</CopyToClipboard>
										</p>
									</div>
								):(
									<></>
								)}

							</div>
						):(
							<></>
						)}

					</div>
				</div>
			</section>

			<BottomMenu></BottomMenu>
		</>
	);

};

export default TeamPage;