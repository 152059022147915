import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { t } from '../translation.js';

// Hooks
import { useSignup } from '../hooks/useSignup';

import FormLogo from '../assets/img/hero-logo.png';

const Register = () => {

	const queryParameters = new URLSearchParams(window.location.search);
	var referrerWallet = queryParameters.get("referral");
	const [referrer, setReferrer] = useState('');

	const [username, setUsername] = useState('');
	const [nationalID, setNationalID] = useState('');
	const [password, setPassword] = useState('');

	const { userSignup, registering, setRegistering, registerFeedback, setRegisterFeedback, registerSuccess, setRegisterSuccess } = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		if (referrer == '') {
			if (referrerWallet == null || referrerWallet == '') {
				await userSignup('default', username, nationalID, password);
			} else {
				await userSignup(referrerWallet, username, nationalID, password);
			}
		} else {
			await userSignup(referrer, username, nationalID, password);
		}
    };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<section className="section-preset register-bg">
			<div className="container">
				<div className="row">
					<div className="col-12 form-page-padding">
						{/* <h3 className="brand-primary text-center mb-3"><strong>注册户口</strong></h3> */}
						<div className="form-container">
							<div className="w-100 text-center">
								<img className="form-logo mb-5" src={FormLogo}></img>
							</div>
							<form className="w-100" onSubmit={handleSubmit}>
								<div className="mb-2">
									<label htmlFor="national_id">
										<strong className="">{t('register-ic')}</strong>
									</label>
									<br></br>
									<input
										type="text"
										placeholder={t('register-ic-placeholder')}
										autoComplete='off'
										name='national_id'
										className='w-100 text-lowercase'
										onChange={(e) => setNationalID(e.target.value.toLowerCase())}
									/>
								</div>
								<div className="mt-3 mb-2">
									<label htmlFor="username">
										<strong className="">{t('register-id')}：</strong>
									</label>
									<br></br>
									<input
										type="text"
										placeholder={t('register-id-placeholder')}
										autoComplete='off'
										name='username'
										className='w-100 text-lowercase'
										onChange={(e) => setUsername(e.target.value.toLowerCase())}
									/>
								</div>
								<div className="mt-3 mb-2">
									<label htmlFor="password">
										<strong className="">{t('register-password')}</strong>
									</label>
									<br></br>
									<input
										type="password"
										placeholder={t('register-password-placeholder')}
										name='password'
										className='w-100'
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<div className="mt-3 mb-2">
									<label htmlFor="referral_code">
										<strong className="">{t('register-referrer')}:</strong>
									</label>
									<br></br>
									<input
										type="text"
										placeholder={t('register-referrer-placeholder')}
										autoComplete='off'
										name='referral_code'
										className='w-100 text-lowercase'
										defaultValue={referrerWallet ? referrerWallet : ''}
										onChange={(e) => setReferrer(e.target.value.toLowerCase())}
									/>
								</div>
								<button disabled={username == '' || nationalID == '' || password == ''} type="submit" className="btn action-btn py-2 mt-3">
									<p className="text-white mb-0"><strong>{t('register-btn')}</strong></p>
								</button>
								{/* {error && <div className="error">{error}</div>} */}
							</form>
							<p className="mt-3 mb-0" style={{fontSize:"14px"}}>{t('register-login')} <a href="/login">{t('register-login2')}</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Register;