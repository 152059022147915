import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

import walletIcon from '../assets/img/wallet.png';
import profileIcon from '../assets/img/profile.png';
import teamIcon from '../assets/img/team.png';
import investIcon from '../assets/img/invest.png';

import { t } from '../translation.js';

const BottomMenu = () => {
  
  return (
    <section className="bottom-menu">
      <div className="container">
        <div className="row">

          <div className="col-3 text-center">
            <NavLink to="/wallet" className="btn py-0 px-2">
              <img className="bottom-menu-icon" src={walletIcon}></img>
              <p className="mt-1 mb-0" style={{fontSize:"10px"}}><strong>{t('wallet')}</strong></p>
            </NavLink>
          </div>

          <div className="col-3 text-center">
            <NavLink to="/team" className="btn py-0 px-2">
              <img className="bottom-menu-icon" src={teamIcon}></img>
              <p className="mt-1 mb-0" style={{fontSize:"10px"}}><strong>{t('team')}</strong></p>
            </NavLink>
          </div>

          <div className="col-3 text-center">
            <NavLink to="/invest" className="btn py-0 px-2">
              <img className="bottom-menu-icon" src={investIcon}></img>
              <p className="mt-1 mb-0" style={{fontSize:"10px"}}><strong>{t('invest')}</strong></p>
            </NavLink>
          </div>

          <div className="col-3 text-center">
            <NavLink to="/profile" className="btn py-0 px-2">
              <img className="bottom-menu-icon" src={profileIcon}></img>
              <p className="mt-1 mb-0" style={{fontSize:"10px"}}><strong>{t('profile')}</strong></p>
            </NavLink>
          </div>

        </div>
      </div>
    </section>
  );

};

export default BottomMenu;