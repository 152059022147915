import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import CSGContract from "../../contracts/CSG.json";
import USDTContract from "../../contracts/USDTContract.json";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == 56) {
          const CSGContractObj = new Web3EthContract(
            CSGContract.abi,
            "0x5E4Ae177f9713e9b498f5De45E409E70b1750B0B"
          );
          const USDTContractObj = new Web3EthContract(
            USDTContract.abi,
            "0x55d398326f99059fF775485246999027B3197955"
          );
          dispatch(
            connectSuccess({
              account: accounts[0],
              csgContract: CSGContractObj,
              usdtContract: USDTContractObj,
              web3: web3,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
            alert("钱包已被更换，请重新登入D-App。");
            window.location.reload();
          });
          ethereum.on("chainChanged", () => {
            alert("成功转换区块链，请重新链接钱包。");
            window.location.reload();
          });
          // Add listeners end
        } else {
          alert("请转换使用币安（BSC）区块链。");
        }
      } catch (err) {
        alert("请链接区块链钱包使用D-App。");
      }
    } else {
      // dispatch(connectFailed("Please install Metamask to use our application."));
      alert("请链接区块链钱包使用D-App。");
    }
  };
  
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};

