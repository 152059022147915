import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import Moment from 'moment';

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import BottomMenu from "../components/bottom-menu.js";

import { useAuthContext } from '../hooks/useAuthContext';

const InvestPage = () => {

	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};

	const [userDepositData, setUserDepositData] = useState(null);
	const fetchUserDepositData = async () => {
		const response = await fetch('https://api.csg9.com/deposit/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserDepositData(json);
			console.log("User Deposit Data:", json);
		}
	};

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
			fetchUserDepositData();
		}
	}, [loggedInUser]);

	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
		blockchain.csgContract.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
		.then((results) => {
			console.log("USDT Balance:", results);
			setUSDTBalance(results);
		});
	};

	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
		blockchain.usdtContract.methods.allowance(blockchain.account, "0x5E4Ae177f9713e9b498f5De45E409E70b1750B0B").call()
		.then((results) => {
			console.log("Token Allowance:", results);
			setAllowance(results);
		});
	};

	const [investing, setInvesting] = useState(false);
	const [investSuccess, setInvestSuccess] = useState(false);
	const investUSDT = () => {
	setInvesting(true);
	blockchain.csgContract.methods
	.invest(3000, false, "0x674cAddD929A995aa1d6e733Fd96f6Bb5436dd98")
	.send({
		gasLimit: 450000,
		maxPriorityFeePerGas: 3000000000,
		maxFeePerGas: 3000000000,
		from: blockchain.account,
		value: 0,
	})
	// Transaction fail
	.once("error", (err) => {
		setInvesting(false);
	})
	// Transaction success
	.then((receipt) => {
		fetch('https://api.csg9.com/deposit/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
            },
            body: JSON.stringify({
				referrer: userData.referrer,
				user_id: userData._id,
				username: userData.username,
				deposit_amount: 3000
            })
        });
		setInvestSuccess(true);
		setTimeout(() => fetchUserDepositData(), 3000);
		setTimeout(() => checkTokenAllowance(), 3000);
		setTimeout(() => setInvesting(false), 5000);
	});
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.csgContract !== null) {
			getUserUSDTBalance();
			checkTokenAllowance();
		}
	};
	
	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						<div className="col-12 custom-page-padding">

							<div className="data-container">
								<div className="row">
									<div className="col-md-6">
										<h3 className="">{t('invest-title')}</h3>
									</div>
									<div className="col-md-6 text-left text-md-right">
										<p className="brand-primary package-price mb-0"><strong>3000 <span style={{fontSize:"20px"}}>USDT</span></strong></p>
									</div>
								</div>
								<hr></hr>
								{userData && !userData.kyc ? (
									<>
										<p className="mb-0">{t('invest-kyc')}</p>
									</>
								):(
									<>
										{userData && userData.wallet== "" ? (
											<>
												<p className="mb-0">{t('invest-wallet-unbinded')}</p>
											</>
										):(
											<>
												{userDepositData && userDepositData.length == 0 ? (
													<>
														{!investing ? <p className="mb-0">{t('invest-record')}</p> : <></>}
														{blockchain.account === "" || blockchain.csgContract === null ? (
															<>
																<button className="btn action-btn py-2 mt-3" onClick={(e) => {
																	e.preventDefault();
																	dispatch(connect());
																	getData();
																}}>
																	<p className="text-white mb-0"><strong>{t('connect-btn')}</strong></p>
																</button>
															</>
														):(
															<>
																{!investing ? (
																	<>
																		{!investSuccess ? (
																			<button className="btn action-btn py-2 mt-3" onClick={(e) => {
																				investUSDT();
																			}}>
																				<p className="text-white mb-0"><strong>{t('invest-btn')}</strong></p>
																			</button>
																		):(
																			<p className="profit-green mb-0" style={{fontSize:"12px"}}><strong>入股成功！</strong></p>
																		)}
																	</>
																):(
																	<>
																		<p className="error mb-0" style={{fontSize:"12px"}}>入股手续正在进行中...</p>
																	</>
																)}

															</>
														)}
													</>
												):(
													<>
														<p className="mb-0"><strong>{t('invest-period')}: </strong> {userData && userData.roi_cycle} / {userData && userData.total_cycle} {t('invest-week')}</p>
														<p className="mb-0"><strong>{t('invest-date')}: </strong>{Moment(userDepositData && userDepositData[0].createdAt).format('YYYY/MM/DD HH:mm')}H</p>
													</>
												)}
											</>
										)}

									</>
								)}
							</div>

							<div className="data-container mt-5">
								<h3 className="">{t('invest-title2')}</h3>
								<hr></hr>
								{userData && !userData.kyc ? <p className="mb-0">{t('invest-kyc')}</p> : <></>}
								{userData && userData.kyc && userData.total_deposit == 0 ? <p className="mb-0">{t('invest-record')}</p> : <></>}
								{userData && userData.kyc && userData.total_deposit != 0 ? <p className="mb-0">盈利还没开始。</p> : <></>}
								{/* <p className="mb-0"><strong>日期: </strong>8-12-2024</p>
								<p className="mb-0"><strong>入股周期: </strong>1 / 18 周</p>
								<p className="mb-0"><strong>当前等级: </strong> 红级</p>
								<p className="mb-0"><strong>静态盈利: </strong> {parseFloat(500).toFixed(2)} USDT</p>
								<p className="mb-0"><strong>团队业绩: </strong> {parseFloat(10000).toFixed(2)} USDT</p>
								<p className="mb-0"><strong>团队盈利: </strong> {parseFloat(200).toFixed(2)} USDT</p>
								<p className="mb-0"><strong>总盈利: </strong> {parseFloat(700).toFixed(2)} USDT</p> */}
							</div>
	
						</div>
					</div>
				</div>
			</section>

			<BottomMenu></BottomMenu>
		</>
	);

};

export default InvestPage;