import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";

import { NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";
import Moment from 'moment';

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import BottomMenu from "../components/bottom-menu.js";

import { useAuthContext } from '../hooks/useAuthContext';
import { useChangePassword } from '../hooks/useChangePassword';
import { useLogout } from '../hooks/useLogout';

const ProfilePage = () => {

	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);

	const { loggedInUser } = useAuthContext();
	const { logout } = useLogout();
	const handleLogout = () => {
	  logout();
	  window.location.href= "/login";
	}

	const [resetPassword, setResetPassword] = useState(false);
	const [curPassword, setCurPassword] = useState('');
	const [newPassword1, setNewPassword1] = useState('');
	const [newPassword2, setNewPassword2] = useState('');
	const { updatePassword, isLoading, error } = useChangePassword();
	const userChangePassword = async (e) => {
		e.preventDefault();
		if (!userData || newPassword1 == '' || newPassword2 == '') {
			return;
		}
		await updatePassword(loggedInUser.token, userData._id, newPassword1);
	};


	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);


	const [usdtBalance, setUSDTBalance] = useState(0);
	const getUserUSDTBalance = () => {
		blockchain.csgContract.methods.getTokenBalance(blockchain.account, "0x55d398326f99059fF775485246999027B3197955").call()
		.then((results) => {
			console.log("USDT Balance:", results);
			setUSDTBalance(results);
		});
	};

	const [allowance, setAllowance] = useState(0);
	const checkTokenAllowance = () => {
		blockchain.usdtContract.methods.allowance(blockchain.account, "0x5E4Ae177f9713e9b498f5De45E409E70b1750B0B").call()
		.then((results) => {
			console.log("Token Allowance:", results);
			setAllowance(results);
		});
	};

	const [approving, setApproving] = useState(false);
	const approveToken = () => {
		setApproving(true);
		blockchain.usdtContract.methods
		.approve("0x5E4Ae177f9713e9b498f5De45E409E70b1750B0B", blockchain.web3.utils.toWei((30000000).toString(), "ether"))
		.send({
			gasLimit: 80000,
			maxPriorityFeePerGas: 3000000000,
			maxFeePerGas: 3000000000,
			from: blockchain.account,
			value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
			setApproving(false);
		})
		// Transaction success
		.then((receipt) => {
			fetch('https://api.csg9.com/user/'+loggedInUser.user._id, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${loggedInUser.token}`
				},
				body: JSON.stringify({
					wallet: blockchain.account
				})
			});
			setTimeout(() => checkTokenAllowance(), 2000);
			setTimeout(() => fetchUserData(), 2000);
			setTimeout(() => setApproving(false), 3000);
		});
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.csgContract !== null) {
			getUserUSDTBalance();
			checkTokenAllowance();
		}
	};
	
	useEffect(() => {
		getData();
	}, [blockchain.account]);
	

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						{userData ? (
							<div className="col-12 custom-page-padding">
								<div className="data-container">
									<h3 className="">{t('profile-title')}</h3>
									<hr></hr>
									{!userData.kyc ? <p className="mb-2"><strong>{t('profile-kyc')}: </strong><i className="fa-solid fa-circle-xmark error mr-2"></i>{t('profile-kyc-no')}</p> : <></>}
									{userData.kyc ? <p className="mb-2"><strong>{t('profile-kyc')}: </strong><i className="fa-solid fa-circle-check profit-green mr-2"></i>{t('profile-kyc-yes')}</p> : <></>}
									<p className="mb-2"><strong>{t('profile-ic')}:</strong> {userData.national_id}</p>
									<p className="mb-0"><strong>{t('profile-referrer')}:</strong> {userData.referrer}</p>
									<button className="btn signout-btn py-2 mt-3" onClick={handleLogout}>
										<p className="text-white mb-0"><strong>{t('logout-btn')}</strong></p>
									</button>
								</div>

								<div className="data-container mt-5">
									<h3 className="">{t('profile-title2')}</h3>
									<hr></hr>
									{blockchain.account === "" || blockchain.csgContract === null ? (
										<>
											{userData && userData.wallet == "" ? (
												<>
													<p className="mb-2"><strong>{t('wallet-address')}:</strong> {userData && userData.wallet == "" ? t('not-binded') : <MiddleEllipsis><span className="mb-0" style={{fontSize:"12px"}}>{userData.wallet}</span></MiddleEllipsis>}</p>
													<button className="btn action-btn py-2 mt-3" onClick={(e) => {
														e.preventDefault();
														dispatch(connect());
														getData();
													}}>
														<p className="text-white mb-0"><strong>{t('connect-btn')}</strong></p>
													</button>
												</>
											):(
												<>
													<p className="mb-2"><strong>{t('wallet-binded')}:</strong> <MiddleEllipsis><span className="mb-0" style={{fontSize:"12px"}}>{userData.wallet}</span></MiddleEllipsis></p>
												</>
											)}
										</>

									):(
										<>
											{userData && userData.wallet == "" || allowance < 30000000*1000000000000000000 ? (
												<>
													{!approving ? (
														<>
															<p className="mb-0"><strong>正在连接的钱包地址：</strong> </p>
															<MiddleEllipsis><span className="mb-0" style={{fontSize:"12px"}}>{blockchain.account}</span></MiddleEllipsis>
															<button className="btn action-btn py-2 mt-3" onClick={(e) => {
																approveToken();
															}}>
																<p className="text-white mb-0"><strong>确认绑定钱包</strong></p>
															</button>
														</>
													):(
														<p className="error mb-0" style={{fontSize:"12px"}}>正在绑定钱包...</p>
													)}

												</>
											):(
												<>
													<p className="mb-2"><strong>已绑定钱包:</strong> <MiddleEllipsis><span className="mb-0" style={{fontSize:"12px"}}>{userData.wallet}</span></MiddleEllipsis></p>
												</>
											)}
										</>
									)}
								</div>

								<div id="reset-password" className="data-container mt-5">
									<h3 className="">{t('profile-title3')}</h3>
									<hr></hr>
									{!resetPassword ? (
										<>
											<p className="mb-2"><strong>{t('login-id')}:</strong> {userData.username}</p>
											<p className="mb-2"><strong>{t('login-password')}:</strong> ********</p>
											<button className="btn action-btn py-2 mt-3" onClick={() => {
												const element = document.getElementById('reset-password');
												element?.scrollIntoView({
													behavior: 'smooth'
												});
												setResetPassword(true);
											}}>
												<p className="text-white mb-0"><strong>{t('change-pass')}</strong></p>
											</button>
										</>
									):(
										<form className="w-100" onSubmit={userChangePassword}>
											<div className="mb-2">
												<label htmlFor="curPassword">
													<strong className="">{t('reset-pass-old')}：</strong>
												</label>
												<br></br>
												<input
													type="password"
													placeholder={t('old-pass')}
													autoComplete='off'
													name='curPassword'
													className='w-100'
													onChange={(e) => setCurPassword(e.target.value)}
												/>
											</div>
											<div className="mt-3 mb-2">
												<label htmlFor="password1">
													<strong className="">{t('reset-pass-new1')}：</strong>
												</label>
												<br></br>
												<input
													type="password"
													placeholder={t('new-pass-1')}
													name='password1'
													className='w-100'
													onChange={(e) => setNewPassword1(e.target.value)}
												/>
											</div>
											<div className="mt-3 mb-2">
												<label htmlFor="password2">
													<strong className="">{t('reset-pass-new2')}：</strong>
												</label>
												<br></br>
												<input
													type="password"
													placeholder={t('new-pass-2')}
													name='password2'
													className='w-100'
													onChange={(e) => setNewPassword2(e.target.value)}
												/>
											</div>
											<button disabled={isLoading || newPassword1 == '' || newPassword2 == '' || curPassword == ''} type="submit" className="btn action-btn py-2 mt-3">
												<p className="text-white mb-0"><strong>{t('reset-password-btn')}</strong></p>
											</button>
											{error && <div className="error">{error}</div>}
										</form>
									)}

								</div>

							</div>
						):(
							<></>
						)}

					</div>
				</div>
			</section>

			<BottomMenu></BottomMenu>
		</>
	);

};

export default ProfilePage;