import Polyglot from 'node-polyglot';
import en from './locales/en.json';
import zh from './locales/zh.json';

const languages = {
  en,
  zh,
};

let polyglot = new Polyglot({ phrases: languages['zh'], locale: 'zh' });

export const setLanguage = (language) => {
  if (languages[language]) {
    polyglot = new Polyglot({ phrases: languages[language], locale: language });
  } else {
    console.warn(`Language ${language} not found, falling back to Chinese.`);
    polyglot = new Polyglot({ phrases: languages['zh'], locale: 'zh' });
  }
};

export const t = (key) => polyglot.t(key);

export default polyglot;
