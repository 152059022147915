import React, { useEffect, useState } from 'react';

import { t } from '../translation.js';

import RedRank from '../assets/img/red.png';
import SilverRank from '../assets/img/silver.png';
import GoldRank from '../assets/img/gold.png';
import PlatinumRank from '../assets/img/platinum.png';

import BottomMenu from "../components/bottom-menu.js";

import { useAuthContext } from '../hooks/useAuthContext.js';

const NewsPage = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
		const response = await fetch('https://api.csg9.com/user/id/'+loggedInUser.user._id, {
			method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${loggedInUser.token}`
			},
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
			console.log("Logged In User Data:", json);
		}
	};
	

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
		}
	}, [loggedInUser]);

	return (
		<>
			<section className="section-preset dashboard-bg">
				<div className="container">
					<div className="row">
						<div className="col-12 custom-page-padding">
							<h3 className="brand-primary text-center">{t('news-main-title')}</h3>
							<div className="data-container">
								<h3 className="">长水集团 CSG 简介</h3>
								<p className="mt-3">{t('news-update-time')}: 28-11-2024 00:00</p>
								<hr></hr>
								<p className="mt-3 mb-0">加入长水集团，您将有机会环游世界各大娱乐场所，诚挚欢迎您成为我们公司的股东之一。您不仅是娱乐参与者，还有机会成为庄家，享受投资收益、保险收益以及自主娱乐的多重回报。</p>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);

};

export default NewsPage;